// Import Fuse core library
//@import "@fuse/scss/core";

// Import app.theme.scss
//@import "app/app.theme";
@import "ngx-toastr/toastr";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";
// @use "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";

// @import '../node_modules/bootstrap/scss/bootstrap-grid.scss';
body {
  font-family: "Circular Std", sans-serif !important;
}
h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
}
a {
  color: #19b133;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
  color: #12a32a;
}
.btn:focus,
button:focus {
  box-shadow: none;
  outline: none;
}
.btn {
  border: 0;
  padding: 10px 30px;
  font-size: 16px;
  font-weight: 600;
  color: #000;
  border-radius: 8px;
}
.btn.btn-primary {
  background: #19b133;
  color: #fff;
}
.btn.btn-default {
  background: #fff;
  border: 1px solid #d0d5dd;
}
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle,
.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #19b133;
  border-color: #19b133;
  outline: none !important;
  box-shadow: none !important;
}
input.form-control {
  height: 45px;
  outline: none !important;
  box-shadow: none !important;
  margin-bottom: 20px;
  border-color: #ced4da !important;
}
.form-group label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}
.form-control {
  outline: none !important;
  box-shadow: none !important;
  border-color: #ced4da !important;
}
#country-search-box {
  @extend .form-control !optional;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.settings .custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}
.settings .custom-switch {
  padding-left: 2.25rem;
}
.settings .custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.settings input[type="checkbox"],
.settings input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
.settings .custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.settings .custom-control-label::before {
  position: absolute;
  top: 0;
  left: -1.5rem;
  display: block;
  width: 60px;
  height: 35px;
  pointer-events: all;
  content: "";
  background-color: #616161;
  border: #adb5bd solid 1px;
  border-radius: 30px;
}
.settings .custom-control-label::before,
.settings .custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
.settings .custom-switch .custom-control-label::before {
  left: -4.25rem;
}
.settings .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #19b133;
  background-color: #19b133;
}
.settings .custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}
.settings .custom-switch .custom-control-label::after {
  top: calc(0.3rem + 0px);
  left: calc(-3.8rem + -2px);
  width: calc(30px - 4px);
  height: calc(30px - 4px);
  background-color: #e6e6e6;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 57px;
}
.settings
  .custom-switch
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background-color: #e6e6e6;
  transform: translateX(1.55rem);
}

.auth {
  padding: 40px 50px;
}
.auth-box {
  width: 400px;
  margin: 0 auto;
  padding: 80px 0px 20px;
  padding-right: 50px;
}
.auth-box form {
  margin-top: 50px;
}
.auth-box .passwordWithIcon {
  position: relative;
}
.auth-box .passwordWithIcon .mat-icon {
  cursor: pointer;
  position: absolute;
  top: 9px;
  right: 10px;
  color: #667085;
  z-index: 1;
}
.auth-box .btn {
  width: 100%;
}
.auth-box .google-icon {
  width: 20px;
  vertical-align: middle;
  margin-right: 5px;
}
.auth-bottom-text {
  text-align: center;
  font-size: 15px;
  color: #000;
  font-weight: 500;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

vertical-layout-1 #main > .container-2 {
  position: relative;
  padding: 30px 0px;
}
@media (max-width: 600px) {
  vertical-layout-1 #main > .container-2 {
    padding: 0;
  }
}

img {
  max-width: 100%;
}
vertical-layout-1 toolbar {
  padding: 30px 15px;
}
.mat-ripple-element {
  width: 0 !important;
  height: auto !important;
}
.mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
  display: none !important;
}
.mat-tab-labels {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}
.manager .mat-tab-labels,
.group-class-detail .tabs-area .mat-tab-labels,
.private-class-detail .tabs-area .mat-tab-labels {
  display: flex;
  justify-content: flex-start;
}
.manager .mat-tab-label-content {
  font-size: 23px;
  font-weight: 600;
  margin-right: 50px;
  margin-bottom: 10px;
}
.private-class-detail .tabs-area .mat-tab-label-content,
.group-class-detail .tabs-area .mat-tab-label-content {
  font-size: 22px;
  font-weight: 600;
  margin-right: 40px;
  margin-bottom: 10px;
}
.private-class-detail .tabs-area .mat-tab-label-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.private-class-detail .tabs-area .mat-tab-label-content span {
  color: #7a7b7a;
  font-size: 17px;
}
.mat-tab-label {
  height: unset !important;
  padding: 5px 0 !important;
  min-width: auto !important;
  opacity: 1 !important;
  font-size: 16px !important;
  color: #000 !important;
  font-weight: 700 !important;
}
.mat-tab-label-active {
  color: #19b133 !important;
}
.mat-tab-body-content {
  overflow: hidden !important;
}

.profile-btn {
  position: absolute;
  top: 35px;
  right: 40px;
  padding: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
  z-index: 11111;
  border: 1px solid #ccc;
  border-radius: 50px;
}
.profile-btn img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.main {
  width: calc(100% - 100px);
  padding: 50px 10px;
}

@media (max-width: 600px) {
  .main {
    width: 100%;

    padding: 50px 10px;
  }
}

/* Side bar */
.side-bar {
  background: #323232;
  width: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 0 40px;
  border-radius: 25px;
  margin: 0 15px;
}
.side-bar .side-logo {
  margin-bottom: 60px;
}
.side-bar ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.side-bar li {
  margin-top: 30px;
}
.side-bar li a {
  cursor: pointer;
}
.side-bar .divider {
  display: block;
  border-top: 1px solid #fff;
  width: 50px;
  margin-top: 60px;
}

.messages ::-webkit-scrollbar,
.calendar-box ::-webkit-scrollbar {
  width: 0px !important;
}

.messages .mat-tab-body-content {
  overflow: auto !important;
}

.profile .profile-box .profile-body .tabs-area .mat-tab-labels {
  margin-bottom: 0;
}
.profile .profile-box .profile-body .tabs-area .mat-tab-header {
  border: 1px solid #ccc;
  padding: 5px 15px;
  border-radius: 10px;
  background: #f7f7f7;
  margin-top: 20px;
  margin-bottom: 40px;
}
.profile
  .profile-box
  .profile-body
  .tabs-area
  .mat-tab-label
  .mat-tab-label-content {
  font-size: 20px;
}
.mobile {
  .profile
  .profile-box
  .profile-body
  .tabs-area
  .mat-tab-label
  .mat-tab-label-content {
  font-size: 14px !important;
}
}

.side-bar li a svg path {
  stroke: #fff;
}
.side-bar li a.active svg path {
  stroke: #19b133;
}
.side-bar li a.fill svg path {
  fill: #19b133;
}

.fa-exclamation-triangle {
  color: #cf0b0b;
}

.text-green {
  color: #19b133;
}

.Yes {
  background-color: #19b133 !important;
  color: #fff !important;
}
.No {
  background-color: #d60727 !important;
  color: #fff !important;
}
.info-tooltip {
  font-size: 18px;
    color: #5d5c5c;
}